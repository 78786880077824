
import { useEffect, useState } from "react";
import { getEventsinner } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb";

const Events = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState();
   const [selectedMonth, setSelectedMonth] = useState("All");
   const [monthOptions, setMonthOptions] = useState(["All"]);
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");
   useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear}`
      setSelectedYear(Year)
   console.log("get current Year", Year)
   }, [])
   useEffect(() => {
      const fetchData = async () => {
         const galleryData = await getEventsinner();
         setData(galleryData);
         const uniqueYears = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.date) {
                  const year = new Date(item.date).getFullYear();
                  return [year];
               } else {
                  return [];
               }
            }))
         );
         const uniqueYearRanges = uniqueYears.map(year => `${year}`);
         uniqueYearRanges.sort((a, b) => b - a);
         setYearRanges(uniqueYearRanges);

         const uniquemonth = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.date) {
                  const month = new Date(item.date).getMonth() + 1;
                  return [month];
               } else {
                  return [];
               }
            }))
         );
         const uniqueMonths = uniquemonth.map(month => `${month}`);
         uniqueMonths.sort((a, b) => b - a);
         setMonthOptions(uniqueMonths);
      };
      fetchData();
   }, []);

   useEffect(() => {
      let filteredData = data;
      if (selectedYear !== "All") {
         filteredData = filteredData.filter((item) => {
            if (item.date && item.date.trim() !== "") {
               const year = new Date(item.date).getFullYear();
               const month = new Date(item.date).getMonth() + 1;
               return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
            } else {
               return false;
            }
         });
      }
      if (selectedMonth !== "All") {
         filteredData = filteredData.filter((item) => {
            if (item.date && item.date.trim() !== "") {
               const month = new Date(item.date).getMonth() + 1;
               return parseInt(selectedMonth) === month;
            } else {
               return false;
            }
         });
      }
      if (searchQuery) {
         filteredData = filteredData.filter((item) =>
            item.title.toLowerCase().includes(searchQuery.toLowerCase())
         );
      }
      setFilteredData(filteredData);
   }, [selectedYear, selectedMonth, searchQuery, data]);


   return (
      <>
         <Header />
         <Breadcrumb pageTitle="Upcoming Events" mid="Events" breadcrumbName="Upcoming Events" />
         <section className="inner-page">
            <div className="container">
               <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
                     <div className="count-val">
                        <p>Total Count: {filterData.length} </p>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
                     <div className="month-selection">
                        <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="yearSelection">
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
                     <div className="month-selection">
                        <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} className="monthSelect" >
                           <option value={"All"}>All</option>
                           {monthOptions.map((month, index) => (
                              <option key={index} value={month}>{month}</option>
                           ))}
                        </select>
                     </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
                     <div className="searchBox">
                        <input type="text" id="myInput" name="name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Here.." />
                     </div>
                  </div>
               </div>
               <div className="row years year2023 showYear">
                  {filterData.length > 0 &&
                     filterData.map((item, index) => (
                        <div className="col-md-4 eventCount" key={index}>
                           <div className="event-blk">
                              
                                 <div className="inr-event-blk">
                                    <div className="inr-event-img">
                                     {item.images.length > 0 ? (<img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St. Thomas School, Mainpuri" />):(
                                       <img src="/Images/about-img.png" className="img-fluid mx-auto d-block" alt="St. Thomas School, Mainpuri" />
                                     )}  
                                    </div>
                                    <div className="inr-event-content">
                                       <h6 className="date">{item.date}</h6>
                                       <h3>{item.title}</h3>
                                       <p>{item.description}</p>
                                       <h6 className="loc">
                                          <i className="bi bi-geo-alt"></i>{item.venue} <span><i className="bi bi-alarm"></i> {item.time}</span>
                                          {item.attachments.length > 0  && <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} target="_blank"><span> View Attachment <i className="bi bi-arrow-right"></i> </span></Link>}
                                       </h6>
                                    </div>
                                 </div>                              
                           </div>
                        </div>
                     ))}
               </div>
               </div>
         </section>
         <Footer />
      </>
   )
}

export default Events