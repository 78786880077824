import React, { useEffect, useState } from 'react';
import { getEvents } from '../Service/Api'; 
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Events = () => {
   
    // var settings = {
    //     loop: true,
    //     margin: 10,
    //     responsiveClass: true,
    //     autoplay: true,
    //     dots: true,
    //     items: 3,
    //     responsive: {
    //         0: {
    //             items: 1,
    //         },
    //         567: {
    //             items: 2,
    //         },
    //         768: {
    //             items: 2,
    //         },
    //         992: {
    //             items: 3,
    //         },
    //         1200: {
    //             items: 2,
    //         }, 
    //         1350: {
    //             items: 3,
    //         },
    //         1600: {
    //             items: 3,
    //         }
    //     }
    // };
    const [data, setData] = useState([]);
    useEffect(() => {
      const getData = async () => {
        const datas = await getEvents();
        console.log(datas)
        setData(datas);
      };
      getData();
    }, []);
    var settings = {
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,   
        arrows:false,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 3, 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,  
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1 
              }
            }
          ]
    }

    return (
        <Slider {...settings}>
             {data?.length > 0 ?
        (data?.map((item, index) => (
            <div className='item' key={index}>
                <div className='evt-blk'>
                    <div className="evt-img">
                        {item.images.length>0 ? (<img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images[0]}`} />):(<img src="/Images/events.jpg" />)}
                        <div className="date">{item.date}  </div>
                        {/* <div className="date">Mar <span>25</span></div> */}
                        
                        <div className="evt-title"><h3>{item.title}</h3></div>
                    </div>
                    <div className='evt-content'>
                        <p>{item.description}</p>
                        <p><img src="/Images/location.png" />{item.location} <span><img src="/Images/clock.png"></img>{item.time}</span></p>
                    </div>
                </div>
            </div>
        
              ))):(<div className="item">
              <div className='evt-blk'>
                  <div className="evt-img">
                      <img src="/Images/events.jpg" />
                      {/* <div className="date">DD.MM</div> */}
                     <div className="date">MM <span>DD</span></div>  
                      
                      <div className="evt-title"><h3>Event Title</h3></div>
                  </div>
                  <div className='evt-content'>
                      <p>Stay Tuned For More Updates</p>
                      <p><img src="/Images/location.png" />School Campus <span><img src="/Images/clock.png"></img>00:00</span></p>
                  </div>
              </div>
          </div>)}
        </Slider>
    );
};

export default Events;
