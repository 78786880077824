
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const Patrons = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Our Patrons" mid="About" breadcrumbName="Our Patrons" />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Our Patrons</h3>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='patrons d-flex'>
                            <img src='/Images/heavenly-patron.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>Our Heavenly Patron</p>
                            <p><span>May Saint Thomas, the courageous Apostle who fearlessly carried the message of Christ to the distant shores of India, inspire us with his unwavering faith and dedication. May his spirit infuse our school with a spirit of excellence and enlightenment, empowering us to nurture thousands of students who will emerge as pillars of progress and prosperity for our society and nation. With Saint Thomas as our guiding light, let us march forward with courage and conviction, confident in our ability to make a meaningful difference in the world.</span>
</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/Jose-Poovathunkal.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>Fr. Jose Poovathunkal</p>
                            <p><span>Founder, St. Thomas School, Mainpuri</span></p></div>
                        </div>
                    </div>
                    {/* <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/George-Alencherry.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>Cardinal Mar. George Alencherry (Emeritus)</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/Joseph-Perumthottam.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>His Grace Mar. Joseph Perumthottam</p>
                            <p><span>Archbishop of Changanacherry</span></p></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/Raphy-Manjaly.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>His Excellency Mar. Raphy Manjaly</p>
                            <p><span>Archbishop of Agra</span></p></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/Thomas-Tharayil.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>His Excellency Mar. Thomas Tharayil</p>
                            <p><span>Auxiliary Bishop of Changanacherry</span></p></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/Thomas-Padiyath.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>His Excellency Mar. Thomas Padiyath</p>
                            <p><span>Auxiliary Bishop of Shamshabad</span></p></div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='patrons'>
                            <img src='/Images/Thomas-Ezhikkadu.jpg' className='img-fluid' />
                            <div className="patrons-cont">
                            <p>Fr. Thomas Ezhikkadu</p>
                            <p><span>Mission Superior, Etawah - Rajasthan Region</span></p></div>
                        </div>
                    </div> */}

                </div> 
            </div>
            <Footer />
        </>
    )
}
export default Patrons